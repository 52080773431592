import React from 'react';
import classNames from 'classnames';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationType, NotificationVariant } from 'xacmn';
import styles from './Notification.module.css';
import { SvgIcon } from '@material-ui/core';

const variantIcon: Record<NotificationVariant, typeof SvgIcon> = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const DURATION = 4000;

interface NotificationProps {
  notification: NotificationType | undefined;
}

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  const handleClose = React.useCallback(
    (event: React.SyntheticEvent, reason: SnackbarCloseReason): void => {
      setOpen(false);
    },
    [],
  );

  const variant = notification?.variant || 'info';
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      className={styles.Snackbar}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={notification?.duration || DURATION}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classNames(classes[variant])}
        message={
          <span className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {notification?.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={styles.close}
            onClick={(evt) => {
              handleClose(evt, 'clickaway');
            }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default Notification;
