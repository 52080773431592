import * as React from 'react';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import TextField,  { TextFieldProps } from '@material-ui/core/TextField';

interface TextMaskCustomProps {
  inputRef?: React.Ref<any>;
}

const TextMaskCustom: React.FC<TextMaskCustomProps> = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        if (typeof inputRef === 'function') {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={emailMask}
    />
  );
}

const Email: React.FC<TextFieldProps> = (props) => {
  const { value, onChange, ...other } = props;

  return (
    <TextField {...other} InputProps={{
      inputComponent: TextMaskCustom,
      value,
      onChange,
    }} />
  );
}

export default Email;
