import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignupForm from '../features/signup/SignupForm';

const SignupRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SignupForm />} />
    </Routes>
  );
};

export default SignupRoutes;
