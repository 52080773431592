import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutline from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import {
  getErrorMessageOfError,
  NotificationType,
  User,
  validateEmail as validateEmail_,
  Validity,
} from 'xacmn';
import Email from './Email';
import './phone-input.css';
import { apiUrl } from '../../config';
import { Link, Typography } from '@material-ui/core';
import Progress from './Progress';
import Notification from './Notification';
import FullscreenMessage from './FullscreenMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formControl: {
      minWidth: 120,
    },
    goodIcon: {
      marginTop: theme.spacing(3),
      color: 'green',
    },
    badIcon: {
      marginTop: theme.spacing(3),
      color: 'red',
    },
    circularProgress: {
      marginTop: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const MOBILE_CHECK_RE = /^[ 0-9+()-]{4,50}$/;
const TENANTNAME_CHECK_RE = /^[a-zA-Z][-0-9a-zA-Z]{3,15}$/;
const USERNAME_CHECK_RE = /^[a-zA-Z][._\-0-9a-zA-Z]{3,15}$/;
const bizRoles = [
  'Business Owner / Executive (CEO, CFO, etc.)',
  'Manager (Operations, Sales, Marketing, etc.)',
  'IT / Technical (Developer, Engineer, IT Manager)',
  'Finance / Human Resources',
  'Administrative / Support Staff',
  'Other (Consultant, Advisor, etc.)',
] as const;

const SignupForm: React.FC = () => {
  const [tenantName, setTenantName] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [fullName, setFullName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [yourCompany, setYourCompany] = React.useState('');
  const [yourRole, setYourRole] = React.useState('');
  const [creating, setCreating] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const [notification, setNotification] = React.useState<NotificationType>();

  const [isUserNameTouched, setIsUserNameTouched] = React.useState(false);
  const [isTenantNameGood, setIsTenantNameGood] = React.useState('NA');
  const [isTenantNameUsed, setIsTenantNameUsed] = React.useState(false);
  const [isEmailGood, setIsEmailGood] = React.useState<Validity>(Validity.NA);
  const [isMobileGood, setIsMobileGood] = React.useState('NA');

  const classes = useStyles();

  const getTenant = (): User => {
    return {
      tenantName,
      userName,
      name: fullName,
      email,
      phone: mobile,
      companyName: yourCompany,
      role: yourRole,
    };
  };

  const createTenant = async () => {
    const tenant = getTenant();
    try {
      setCreating(true);
      const response = await fetch(`${apiUrl}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(tenant),
      });
      if (response.status !== 200) {
        const msg = await response.text();
        throw new Error(msg);
      }
      // TODO: make sure the new sign-in domain name is available!
      // it is to wait for DNS to propagate to my computer!
      // Do not do it here! do it in app when login
      // before redirect, detect whether the DNS is OK
      // if not, prompt and wait and retry
      return true;
    } catch (err) {
      const msg = getErrorMessageOfError(err);
      setNotification({ variant: 'error', message: msg });
      return false;
    } finally {
      setCreating(false);
    }
  };

  const isValid = () => {
    return (
      isEmailGood === Validity.Yes &&
      fullName.trim().length >= 2 &&
      isTenantNameGood === 'Yes' &&
      USERNAME_CHECK_RE.test(userName)
    );
  };

  const validateTenantName = async () => {
    const _tenantName = tenantName.trim();

    // basic checks
    if (_tenantName.length === 0) {
      setIsTenantNameGood('NA');
      return;
    }
    if (!TENANTNAME_CHECK_RE.test(_tenantName)) {
      setIsTenantNameGood('No');
      return;
    }

    setIsTenantNameGood('WIP');
    try {
      const res = await fetch(
        `${apiUrl}/tenant?` +
          new URLSearchParams({
            tenantName: _tenantName,
          }),
      );
      if (res.status === 200) {
        setIsTenantNameUsed(true);
        setIsTenantNameGood('No');
      } else if (res.status === 404) {
        setIsTenantNameGood('Yes');
        setTenantName(_tenantName);
      } else {
        const msg = await res.text();
        throw new Error(`${res.status} - ${msg}`);
      }
    } catch (err) {
      setIsTenantNameGood('NA');
      setNotification({ variant: 'error', message: getErrorMessageOfError(err) });
    }
  };

  const validateEmail = async () => {
    await validateEmail_(apiUrl, email, setIsEmailGood, setEmail);
  };

  const validateMobile = async () => {
    const _mobile = mobile.trim();

    // basic checks
    if (_mobile.length <= 2) {
      setIsMobileGood('NA');
      return;
    }
    if (!MOBILE_CHECK_RE.test(_mobile)) {
      setIsMobileGood('No');
      return;
    }

    setIsMobileGood('WIP');
    return await fetch(`${apiUrl}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        action: 'verifyPhone',
        payload: _mobile,
      }),
    })
      .then((res) => res.json())
      .then((r) => {
        const good = r.phone_valid;
        if (good) {
          setIsMobileGood('Yes');
          setMobile(_mobile);
        } else {
          setIsMobileGood('No');
        }
      })
      .catch((error) => {
        setIsMobileGood('NA');
        setNotification({ variant: 'error', message: getErrorMessageOfError(error) });
      });
  };

  if (created) {
    return (
      <FullscreenMessage
        info={`<div style="
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #444;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
">
    <h2 style="
        font-size: 24px;
        margin-bottom: 20px;
        color: #333;
    ">Congratulations!</h2>
    <p style="
        font-size: 16px;
        color: #666;
    ">
        You have successfully signed up with our platform! We have sent the sign-in details to your email address - ${email}.
    </p>
    <p style="
        font-size: 16px;
        color: #666;
    ">
        Please check your inbox to access your account. To return to our homepage, please 
        <a href="https://www.excelapp.io" style="color: #1a73e8; text-decoration: none;">click here</a>.
    </p>
    <p style="
        font-size: 16px;
        color: #666;
    ">
        Welcome to our growing community, and thank you for choosing us.
    </p>
</div>`}
      />
    );
  }

  return (
    <div>
      <Notification notification={notification} />
      <Progress open={creating} />
      <form id="xlapp-signup-form" className={classes.root}>
        <Grid container spacing={3} style={{ width: '640px' }}>
          <Grid item xs={11}>
            <TextField
              required
              fullWidth
              id="tenant-name"
              label="Account Name"
              value={tenantName}
              onChange={(e) => {
                setIsTenantNameUsed(false);
                setTenantName(e.target.value as string);
              }}
              onBlur={validateTenantName}
            />
            <Typography variant="caption" color="textSecondary">
              Use 3 to 15 characters, starting with a letter, and including letters, numbers, and
              hyphens (-).
              <br />
              Your login URL will be{' '}
              <Link href="#" onClick={(e) => e.preventDefault()}>
                https://app.excelapp.io/{tenantName}
              </Link>
              .
            </Typography>
            {isTenantNameUsed ? (
              <>
                <br />
                <Typography variant="caption" color="error">
                  This account name is already used. Please choose another one.
                </Typography>
              </>
            ) : null}
          </Grid>
          <Grid item xs={1}>
            {isTenantNameGood === 'Yes' ? (
              <CheckCircleOutline className={classes.goodIcon} />
            ) : isTenantNameGood === 'No' ? (
              <HighlightOff className={classes.badIcon} />
            ) : isTenantNameGood === 'WIP' ? (
              <CircularProgress size={24} className={classes.circularProgress} />
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <TextField
              required
              fullWidth
              id="user-name"
              label="Login Name"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value as string);
                setIsUserNameTouched(true);
              }}
            />
            <Typography variant="caption" color="textSecondary">
              Use 3 to 15 characters, starting with a letter, and including letters, numbers,
              underscores (_), hyphens (-), and periods (.).
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {isUserNameTouched ? (
              USERNAME_CHECK_RE.test(userName) ? (
                <CheckCircleOutline className={classes.goodIcon} />
              ) : (
                <HighlightOff className={classes.badIcon} />
              )
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <TextField
              required
              fullWidth
              id="full-name"
              label="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value as string)}
            />
          </Grid>
          <Grid item xs={11}>
            <Email
              required
              fullWidth
              id="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value as string)}
              onBlur={validateEmail}
            />
            <Typography variant="caption" color="textSecondary">
              You will receive an email containing your login instructions and initial password
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {isEmailGood === Validity.Yes ? (
              <CheckCircleOutline className={classes.goodIcon} />
            ) : isEmailGood === Validity.No ? (
              <HighlightOff className={classes.badIcon} />
            ) : isEmailGood === Validity.WIP ? (
              <CircularProgress size={24} className={classes.circularProgress} />
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <PhoneInput
              country={'us'}
              enableSearch
              disableSearchIcon
              value={mobile}
              onChange={(phone) => setMobile(phone)}
              onBlur={validateMobile}
            />
          </Grid>
          <Grid item xs={1}>
            {isMobileGood === 'Yes' ? (
              <CheckCircleOutline className={classes.goodIcon} />
            ) : isMobileGood === 'No' ? (
              <HighlightOff className={classes.badIcon} />
            ) : isMobileGood === 'WIP' ? (
              <CircularProgress size={24} className={classes.circularProgress} />
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              id="your-company"
              label="Your Company"
              value={yourCompany}
              onChange={(e) => setYourCompany(e.target.value as string)}
            />
          </Grid>
          <Grid item xs={11}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="your-role-label">Your Role</InputLabel>
              <Select
                labelId="your-role-label"
                id="your-role-select"
                value={yourRole}
                onChange={(e) => setYourRole(e.target.value as string)}
              >
                {bizRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              * Required fields.
              <br />
              By signing up, you agree to the&nbsp;
              <Link href="https://www.excelapp.io/terms-of-service/" target="_blank">
                Terms of Service
              </Link>
              &nbsp;and&nbsp;
              <Link href="https://www.excelapp.io/privacy-policy/" target="_blank">
                Privacy Policy
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="xlapp-signup-form"
              value="Submit"
              disabled={!isValid()}
              onClick={async (e) => {
                e.preventDefault();
                await createTenant().then((isGood) => {
                  if (isGood) {
                    setCreated(true);
                  }
                });
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default SignupForm;
