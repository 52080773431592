import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Routes from './Routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    contentTopPadder: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

export default function Frame() {
  const classes = useStyles();

  const returnToInfoSite = () => {
    window.location.href = 'https://www.excelapp.io';
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={returnToInfoSite}
            edge="start"
            className={classes.menuButton}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <span role="img" aria-label="Rocket">
              🚀
            </span>
            &nbsp; Sign Up for Free BETA
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.contentTopPadder} />
        <Routes />
      </main>
    </div>
  );
}
